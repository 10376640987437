exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-begleitung-js": () => import("./../../../src/pages/begleitung.js" /* webpackChunkName: "component---src-pages-begleitung-js" */),
  "component---src-pages-bestattungen-js": () => import("./../../../src/pages/bestattungen.js" /* webpackChunkName: "component---src-pages-bestattungen-js" */),
  "component---src-pages-hilfe-im-trauerfall-js": () => import("./../../../src/pages/hilfe-im-trauerfall.js" /* webpackChunkName: "component---src-pages-hilfe-im-trauerfall-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-vorsorge-js": () => import("./../../../src/pages/vorsorge.js" /* webpackChunkName: "component---src-pages-vorsorge-js" */)
}

